import { Typography } from '@/components/Atoms';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { HomeDetailDataType } from '../../HeroSection';
import { getDirectusImageUrl } from '@/utils/getUrl';
import { useTranslate } from '@tolgee/react';
import dynamic from 'next/dynamic';
import GameButton from '@/components/StartMining/GameButton';
interface IBitcoinSection {
  data: HomeDetailDataType;
  imageId?: string;
  mobileImageId?: string;
}
const DynamicBannerCountdown = dynamic(() => import('@/components/Builder/CommandCenterBanner/BannerCountdown'), { ssr: true });
const BitcoinSection = ({ data, imageId, mobileImageId }: IBitcoinSection) => {
  const [isDesktopView, setIsDesktopView] = useState(false);
  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)',
  });
  const { t } = useTranslate();

  useEffect(() => {
    setIsDesktopView(isDesktop);
  }, [isDesktop]);

  if (!data) return null;

  const { title, subtitle, button } = data;

  console.log(data);

  const bgMobImage = mobileImageId || imageId;

  return (
    <div
      className='px-4 sm:px-7 md:px-10 lg:px-16 xl:px-20 relative w-full h-[85vh] overflow-hidden  
    bg-bottom lg:bg-center flex items-center justify-center lg:justify-start bg-no-repeat bg-cover'
      // style={{
      //   backgroundImage: !isDesktop ? `url("${getDirectusImageUrl(bgMobImage)}")` : `url("${getDirectusImageUrl(imageId)}")`,
      // }}
    >
      <video
        src='https://landrocker-directus.infiniteeight.io/assets/a6b59eab-23e1-45d3-973a-53226fca7e2c'
        autoPlay
        loop
        muted
        playsInline
        className='absolute top-0 left-0 object-cover w-full h-full -z-30'
      />

      {/* Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-r from-black via-black/20 to-transparent"></div>
     


      <div className='flex flex-col items-center lg:items-start'>
        {/* Content */}
        <div className='relative z-10 max-w-4xl mx-auto px-4 text-center lg:text-left'>
          <div className='flex flex-col gap-y-2 mb-4 mt-4 sm:mt-0 sm:mb-6'>
            <h1 className='text-5xl md:text-7xl xl:text-[50px] font-bold xl:text-nowrap'>LandRocker <span className='block xl:inline'>Bitcoin Hunt</span></h1>
            <h2 className='text-4xl md:text-6xl font-bold xl:text-[70px] text-[#FFB800]'>IS LIVE NOW!</h2>

          </div>

          <p className='text-xl md:text-2xl mb-1 font-bold'>After 8 months of innovation</p>
          <p className='text-xs md:text-lg mb-4 text-white max-w-3xl'>
            and the development of seven games across multiple platforms,{<br />} LandRocker's most crucial piece of its ecosystem is live now.
          </p>

          <p className='text-[#FFB800] text-xl md:text-2xl font-bold'>
            This milestone will complete the puzzle{<br />} and propel the entire ecosystem to an{<br />} unprecedented level.
          </p>

          <div className='w-full flex justify-center lg:justify-start items-center lg:mx-0 mt-8 sm:mt-10 w-[180px] sm:w-auto'>
            <GameButton isDisabled={false} className={'bitcoin_hunt'} text={'Play Now'} link={'https://hidden-bitcoin.com'} isComingSoon={false} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BitcoinSection;
